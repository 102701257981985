import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-22 13:56:50
 */

//获取表格数据，以及筛选
export function getPageList(parameter){
    return axios({
        url: '/settled/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//批量审核
export function auditBatch(parameter){
    return axios({
        url: '/settled/batchAudit',
        method: 'post',
        data: parameter
    })
}
//单独审核
export function auditOne(parameter){
    return axios({
        url: '/settled/audit',
        method: 'post',
        data: parameter
    })
}
//详情
export function auditDetail(parameter){
    return axios({
        url: '/settled/detail',
        method: 'post',
        params: parameter
    })
}

/**
* 模块名:
* 代码描述: 根据代理商code获取代理类目
* 作者:陈莉莉
* 创建时间:2022-09-29 16:44:01
*/
export function findCategoryListByAgentCode(parameter){
    return axios({
        url: "/agent/goodCategoryList",
        method: "POST",
        params: parameter
    })
}

/**
* 模块名:
* 代码描述: 编辑入驻信息
* 作者:陈莉莉
* 创建时间:2022-09-29 16:45:03
*/
export function editSettledText(parameter){
    return axios({
        url: "/settled/edit",
        method: "POST",
        data: parameter
    })
}
